
import {Options, Vue} from 'vue-class-component';
import CreationImage from "@/components/CreationImage.vue";
import {CommunityCreationData} from "@/types/CommunityCreationData";
import {getCommunityCreations, updateEditorChoice} from "@/api";
import UserAvatar from '@/components/UserAvatar.vue';
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import LoadingPanel from "@/components/LoadingPanel.vue";
import RotateRing from "@/components/RotateRing.vue";
import LazyLoadContainer from "@/components/LazyLoadContainer.vue";
import {colorLog, isMobile} from "@/utils";
import {ElMessage} from "element-plus";
import MetaButton from "@/components/MetaButton.vue";
import FadeTransitionGroup from '@/components/transitions/FadeTransitionGroup.vue'

enum Sorting {
  TRENDING = 'trending',
  LATEST = 'latest'
}
type CreationColumns = [CommunityCreationData[],CommunityCreationData[],CommunityCreationData[]];
type PageInfo = {
  start: number,
  end: number,
  increase: number,
  imgSize: 'small' | 'large'
}

@Options<Community>({
  components: {
    MetaButton,
    CreationImage,
    UserAvatar,
    LoadingPanel,
    FadeTransition,
    RotateRing,
    LazyLoadContainer,
    FadeTransitionGroup,
  },
  props: {},
  computed:{
    smallCreationColumns(){
      return this.smallCreations.reduce((columns:CreationColumns, creation:CommunityCreationData, index:number) => {
        const pushIndex = index % (this.isMobile ? 1 : 3 );
        columns[pushIndex].push(creation);
        return columns;
      },Array(this.isMobile ? 1 : 3).fill(null).map(_ => []))
    }
  }
})
export default class Community extends Vue {
  smallCreations:CommunityCreationData[] = [];
  largeCreations:CommunityCreationData[] = [];
  initialLoading = true;
  switchLoading = false;
  sorting = Sorting.TRENDING;
  Sorting = Sorting;
  smallCreationColumns!:CreationColumns;
  smallPageInfo:PageInfo = {
    start:-1,
    end:-1,
    increase:-1,
    imgSize:'small'
  };
  largePageInfo:PageInfo = {
    start:-1,
    end:-1,
    increase:-1,
    imgSize:'large'
  }
  obtMode = false;
  isMobile = isMobile();

  $refs!:{
    largeColumn:Element,
    smallColumn:Element[] | Element
  }

  created(){
    this.resetPageInfo();
    this.appendCreations(this.smallPageInfo,true);
    this.appendCreations(this.largePageInfo,true);
    this.initialLoading = false;
    if(this.$route.query.obt==='1'){
      this.obtMode=true;
    }
  }

  resetPageInfo(){
    this.smallPageInfo = {
      start : 0,
      end:this.isMobile ? 6 : 12,
      increase: this.isMobile ? 3 : 12,
      imgSize:'small'
    };

    this.largePageInfo = {
      start : 0,
      end:this.isMobile ? 4 : 2,
      increase: this.isMobile ? 2 : 2,
      imgSize:'large'
    }
  }

  async handleSortingBtnClicked(sorting:Sorting){
    if(this.sorting !== sorting){
      this.switchLoading = true;
      this.sorting = sorting;
      this.smallCreations = [];
      this.largeCreations = [];
      this.resetPageInfo();
      await this.appendCreations(this.smallPageInfo,true);
      await this.appendCreations(this.largePageInfo,true);
      this.switchLoading = false;
    }else{
      this.sorting = sorting;
    }
  }

  async handleLazyLoad(resolveFn:() => void){
    const {largeColumn,smallColumn} = this.$refs;
    const largeColumnLastChild = largeColumn.lastElementChild!;
    let largeColumnHeight = largeColumn.clientHeight;
    let smallColumnHeight = 0;
    if(Array.isArray(smallColumn)){
      smallColumnHeight = Math.max(...smallColumn.map(c => c.clientHeight));
    }else{
      smallColumnHeight = smallColumn.clientHeight;
    }

    if((smallColumnHeight - largeColumnHeight) > (largeColumnLastChild.clientHeight / 2)){
      colorLog('height of large column  not enough')
      await this.appendCreations(this.largePageInfo,undefined,this.largePageInfo.increase + 1);
    }else{
      await this.appendCreations(this.largePageInfo);
    }

    if((largeColumnHeight - smallColumnHeight) > (largeColumnLastChild.clientHeight / 2) ){
      colorLog('height of small column  not enough')
      await this.appendCreations(this.smallPageInfo,undefined,this.smallPageInfo.increase + 1);
    }else{
      await this.appendCreations(this.smallPageInfo);
    }
    resolveFn();
  }

  async appendCreations(targetPageInfo:PageInfo,initial = false,increase?:number){
    const target = targetPageInfo.imgSize === 'small' ? this.smallCreations : this.largeCreations;

    let start = 0;
    let end = 0;
    increase = increase ?? targetPageInfo.increase;

    if(!initial){
      start = targetPageInfo.end;
      end = targetPageInfo.end + increase
    }else{
      start = targetPageInfo.start;
      end = targetPageInfo.end;
    }
    const creations = await getCommunityCreations(
        start,
        end,
        targetPageInfo.imgSize,
        this.sorting === Sorting.TRENDING ? undefined : 'time');

    if(creations.length){
      if(!initial){
        targetPageInfo.start = targetPageInfo.end;
        targetPageInfo.end += increase;
      }

      target.push(...creations);
    }else{
      // ElMessage.info(this.$t('noMoreContent'));
    }
  }

  editorChoice(id:string|number,value:boolean){
    updateEditorChoice(id,value).then(res=>{
      if(res.data.code===200){
        ElMessage.success('修改成功')
      }
    })
  }
}
