
import {Vue, Options} from 'vue-class-component';
import anime from "animejs";

@Options<FadeTransitionGroup>({
  components: {},
  props: {}
})
export default class FadeTransitionGroup extends Vue {
  onBeforeEnter(el:Element){

  }
  onEnter(el:Element,done:() => void){
    anime({
      targets:el,
      opacity:[0,1],
      translateY:[15,0],
      easing:'easeInCubic',
      duration:400,
      complete:() => done()
    });
  }
  onLeave(el:Element,done:() => void){
    anime({
      targets:el,
      opacity:[1,.1],
      easing:'easeOutCubic',
      duration:400,
      complete:() => done()
    });
  }
}
